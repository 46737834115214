export const userInitState = {
  authMethod: null,
  username:null,
  password:null,
  email:null,
  cognitoUsername: null,
  accessToken:null,
  clientId:null,
  org:null,
  orgData:null,
};